import React, { Component } from 'react'
import { navigate } from 'gatsby'

class Boundary extends Component<
  any,
  {
    error?: any
    info?: any
  }
> {
  state = {
    error: null,
    info: { componentStack: null },
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ error, info })
  }

  componentDidUpdate() {
    const { error } = this.state
    if (error !== null) navigate(`/`)
  }

  render() {
    const { error, info } = this.state
    return error !== null ? (
      <div className="min-h-screen flex justify-center items-center">
        <pre>
          {`${error}`}
          <br />
          {info?.componentStack}
        </pre>
      </div>
    ) : (
      this.props.children
    )
  }
}

export default Boundary
export { Boundary }
