import React from 'react'
import { Boundary } from '../components/boundary'
import { Preview } from '../components/preview'

const PreviewPage = () => {
  return (
    <Boundary>
      <Preview />
    </Boundary>
  )
}

export default PreviewPage
