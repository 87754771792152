import React, { ReactElement, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import {
  mergePrismicPreviewData,
  usePrismicPreview,
} from 'gatsby-source-prismic'
import { usePreview } from '../hooks/usePreview'
import { linkResolver } from '../utilities'

interface PreviewProps {
  children?: ReactElement
}

const Preview = ({ children }: PreviewProps) => {
  const previewContext = usePreview(),
    setContext = previewContext.setContext,
    preview = usePrismicPreview({
      repositoryName: `lukeedwardhall`,
      linkResolver: (input) => () => linkResolver(input.node),
    }),
    newContext = {
      ...previewContext,
      exists: true,
      mergePrismicPreviewData,
      ...preview,
    },
    [navigated, setNavigated] = useState(false),
    { pathname } = useLocation(),
    { path, previewData } = preview

  // Update context
  useEffect(() => {
    if (setContext && previewContext.previewData !== previewData)
      setContext(newContext)
  }, [setContext, newContext, previewContext, previewData])

  // This useEffect runs when values from usePrismicPreview update. When
  // preview data is available, this will save the data globally and redirect to
  // the previewed document's page.
  useEffect(() => {
    // If we've already sent someone on their way, don't keep messing with them
    if (navigated) return

    if (path) {
      navigate(path)
      setNavigated(true)
    }
  }, [navigated, setNavigated, path, pathname])

  return (
    <div className="fixed inset-0 z-40 flex items-center justify-center bg-white" />
  )
}

export default Preview
export { Preview }
